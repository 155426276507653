import React, { useState } from "react"
import useUser from "@/lib/useUser"
import fetchJson from "@/lib/fetcher"
import s from "./OTCComponent.module.scss"
import { Input, Button, Form, Space, Typography, Card } from "antd"
import { useRouter } from "next/router"
const OTCComponent = (props) => {
	// here we just check if user is already logged in and redirect to profile
	const { mutateUser } = useUser({
		redirectTo: "/dashboard",
		redirectIfFound: true,
	})
	const router = useRouter()
	const [isOTCGenerated, setIsOTCGenerated] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")
	const [username, setUsername] = useState("")
	async function generateOTC(values) {
		setErrorMessage("")
		setIsLoading(true)
		setUsername(values.email)
		try {
			const login = await fetchJson("/api/auth/otc", {
				method: "POST",
				body: JSON.stringify({ username: values.email }),
			})
			if (!login?.success) {
				setIsLoading(false)
				return setErrorMessage(login.message)
			}
			setIsOTCGenerated(true)
			setIsLoading(false)
		} catch (error) {
			setErrorMessage(
				"There was an error generating a one time code. Please try again."
			)
			setIsLoading(false)
		}
	}
	const changeEmail = () => {
		setErrorMessage("")
		setIsOTCGenerated(false)
	}
	async function handleSubmit(values) {
		setErrorMessage("")
		setIsLoading(true)
		try {
			const cookieDetails = await fetchJson("/api/auth/otclogin", {
				method: "POST",
				body: JSON.stringify({ username, otc: values.otc }),
			})
			if (!cookieDetails?.success) {
				setIsLoading(false)
				return setErrorMessage(cookieDetails.message)
			}
			const userDetails = await fetchJson("/api/auth/user", {
				method: "POST",
				body: JSON.stringify({ id: cookieDetails.entityId }),
			})
			if (!userDetails?.success) {
				setIsLoading(false)
				return setErrorMessage(userDetails.message)
			}
			if (userDetails.success) {
				router.push("/dashboard")
			}
			setIsLoading(false)
		} catch (error) {
			setErrorMessage("There was an error logging in. Please try again.")
			setIsLoading(false)
			console.log(error)
		}
	}

	return isOTCGenerated ? (
		<Form name="login" onFinish={handleSubmit}>
			{errorMessage && (
				<Typography.Paragraph type="danger">{errorMessage}</Typography.Paragraph>
			)}
			<Form.Item
				label="One Time Code"
				name="otc"
				rules={[
					{
						required: true,
						message: "Please input the OTC that was sent to your email.",
					},
				]}
			>
				<Input type="text" />
			</Form.Item>

			<Form.Item wrapperCol={{ offset: 12, span: 12 }}>
				<Space>
					<Button
						disabled={isLoading}
						onClick={() => generateOTC({ email: username })}
					>
						Resend OTC
					</Button>
					<Button loading={isLoading} type="primary" htmlType="submit">
						Login
					</Button>
				</Space>
				<Button type="link" onClick={() => changeEmail()}>
					Login with a different email
				</Button>
			</Form.Item>
		</Form>
	) : (
		<Form onFinish={generateOTC} name="otc">
			{errorMessage && (
				<Typography.Text type="danger">{errorMessage}</Typography.Text>
			)}
			<Form.Item
				wrapperCol={{ offset: 4, span: 20 }}
				label="Email"
				name="email"
				rules={[{ required: "true", message: "An email is required" }, { type: 'email', message: 'A valid email is required'}]}
			>
				<Input type="text" placeholder="email@example.com" />
			</Form.Item>
			<Form.Item>
				<Button loading={isLoading} type="primary" block htmlType="submit">
					Submit
				</Button>
			</Form.Item>
		</Form>
	)
}

export default OTCComponent
