import React, { useState } from "react";
import useUser from "@/lib/useUser";
import { OTCComponent, PasswordLogin } from "@/components/pages";
import { Card, Row, Col } from "antd";
import { useRouter } from "next/router";
import Image from "next/image";
const LoginComponent = (props) => {
  // here we just check if user is already logged in and redirect to profile
  const { mutateUser } = useUser({
    redirectTo: "/dashboard",
    redirectIfFound: true,
  });
  const router = useRouter();
  return (
    <Card style={{ padding: "20px 10px", width: "100%", maxWidth: 600, borderRadius: "6px" }} data-testid="passwordlogin">
      <Row justify="center" align="center" wrap>
        <Col xs={24}>
          <div style={{ height: 100, position: 'relative', marginBottom: 20 }}>
            <Image src="/ec_logo.jpg" objectFit="contain" layout="fill" alt="Element Critical logo"/>
          </div>
          {router.query?.mode && router.query?.mode == "otc" ? <OTCComponent /> : <PasswordLogin />}
        </Col>
      </Row>
    </Card>
  );
};
export default LoginComponent;
