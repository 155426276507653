import Login from './PasswordLogin'
import SetPassword from './SetPassword'
import React, { useState } from 'react'
import { useRouter } from 'next/router'

const PasswordLogin = () => {
  const router = useRouter()
  const [isFirstLogin, setisFirstLogin] = useState(false)
  return (
    router.query?.view && router.query?.view == 'set_password' ? 
    <SetPassword isFirstLogin={isFirstLogin} /> : <Login setisFirstLogin={setisFirstLogin} />
  )
}

export default PasswordLogin
