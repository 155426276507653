import dayjs from 'dayjs';

const dateUtils = {};

dateUtils.formatNetsuiteSiteLocationAccessDate = function(date, location) {
    var dateString = date.toDateString();
    var hour = '23';
    switch(location) {
        case '2':
        case '3':
        case '12':
        case '13':
            hour = '21';
            break;
        case '5':
            hour = '20';
            break;
    }

    dateString = dateString + ' ' + hour + ':59:00 ' + process.env.NEXT_PUBLIC_NETSUITE_TIME_ZONE;
    return new Date(dateString);
}

dateUtils.formatLocationAccessDateFromNetsuite = function(date) {
    if (typeof date === 'string') {
        return dayjs(date).subtract(10, 'hour');
    }
    else {
        return date;
    }
}

export default dateUtils;