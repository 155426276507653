import React, { useState } from "react"
import fetchJson from "@/lib/fetcher"
import { Input, Button, Form, Typography, Row, notification, Space } from "antd"
import { useRouter } from "next/router"

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const SetPassword = (props) => {
	const [errorMessage, setErrorMessage] = useState("")
	const [isLoading, setIsLoading] = useState(false)

	const router = useRouter()

	const resendOtc = async () => {
		const resetPassword = await fetchJson("/api/auth/resetpassword", {
			method: "POST",
			body: JSON.stringify({
				username: form.getFieldValue("username"),
			}),
		})
		if (!resetPassword?.success) {
			setIsLoading(false)
			return setErrorMessage(resetPassword.error)
		} else {
			setIsLoading(false)
			notification.success({
				message: "A new one time code was sent to your email.",
				duration: 3,
			})
		}
	}
	const handleSubmit = async (values) => {
		setErrorMessage("")
		setIsLoading(true)
		try {
			const setPassword = await fetchJson("/api/auth/setpassword", {
				method: "POST",
				body: JSON.stringify({
					username: values.username,
					resetcode: values.otc,
					password: btoa(values.password),
				}),
			})
			if (!setPassword?.success) {
				setIsLoading(false)
				console.log(setPassword, "set password")
				return setErrorMessage(setPassword.error)
			} else {
				notification.success({
					message:
						"Your password has been set successfully. Please login to continue.",
					duration: 3,
				})
				return router.push("/login")
			}
			setIsLoading(false)
		} catch (error) {
			console.log(error, "error")
			setErrorMessage("There was an error logging in. Please try again.")
			setIsLoading(false)
		}
	}
  const [form] = Form.useForm()
	return (
		<div>
			<Typography.Paragraph style={{ whiteSpace: "pre-wrap" }}>
				{props.isFirstLogin
					? "A password has not been set for your account.\nA one time code has been sent to your email to verify. Please enter it below to create a password"
					: "A one time code was sent to your email, enter it below to set your new password"}
			</Typography.Paragraph>
			<Form {...formItemLayout} form={form} name="login" onFinish={handleSubmit}>
				{errorMessage && (
					<Typography.Paragraph type="danger">
						{errorMessage}
					</Typography.Paragraph>
				)}
				<Form.Item
					label="One Time Code"
					name="otc"
					rules={[
						{
							required: true,
							message: "Please input the OTC that was sent to your email.",
						},
					]}
				>
					<Input type="text" />
				</Form.Item>
				<Form.Item
					label="Email"
					name="username"
					rules={[
						{
							required: true,
							message: "Please provide your email.",
						},
						{
							type: "email",
							message: "A valid email is required.",
						},
					]}
				>
					<Input type="email" />
				</Form.Item>

				<Form.Item
					label="Password"
					name="password"
					rules={[
						{
							required: true,
							message: "Please provide a password.",
						},
						{
							min: 8,
							message: "Your password must be at least 8 characters long",
						},
					]}
				>
					<Input.Password type="password" />
				</Form.Item>
				<Form.Item
					label="Confirm Password"
					name="confirm"
					dependencies={["password"]}
					rules={[
						{
							required: true,
							message: "Please confirm your password",
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue("password") === value) {
									return Promise.resolve()
								}

								return Promise.reject(
									new Error("The two passwords that you entered do not match")
								)
							},
						}),
					]}
				>
					<Input.Password type="password" />
				</Form.Item>

				<Row justify="end">
					<Form.Item>
						<Space>
							<Button disabled={isLoading} onClick={() => resendOtc(form)}>
								Send New OTC
							</Button>
							<Button loading={isLoading} type="primary" htmlType="submit">
								Set Password
							</Button>
						</Space>
					</Form.Item>
				</Row>
			</Form>
		</div>
	)
}

export default SetPassword
