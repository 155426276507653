import React, { useState } from "react";
import useUser from "@/lib/useUser";
import fetchJson from "@/lib/fetcher";
import { Input, Button, Form, Typography, Row, Space } from "antd";
import { useRouter } from "next/router";
import netsuiteLib from "@/lib/netsuitelib";
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      offset: 0,
      span: 18,
    },
    md:{
      offset: 0,
      span: 20,
    }
  },
};

const PasswordLogin = (props) => {
  // here we just check if user is already logged in and redirect to profile
  const { user, mutateUser } = useUser({
    redirectTo: "/dashboard",
    redirectIfFound: true,
  });

  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  async function handleLogin(values) {
    console.log(values, "values");
    setErrorMessage("");
    setIsLoading(true);
    try {
      const login = await fetchJson("/api/auth/passwordlogin", {
        method: "POST",
        body: JSON.stringify({
          username: values.email,
          password: btoa(values.password),
        }),
      });
      console.log(login, "login response form api");
      if (!login?.success && login.errorCode == "PASSWORD_NOT_SET") {
        props.setisFirstLogin(true);
        setIsLoading(false);
        return router.push("/login?view=set_password");
      } else if (login.error == "User not found" && login.errorCode == "UNEXPECTED_ERROR") {
        setErrorMessage("Your account has not been set up yet. Please click the first time login below.");
        setIsLoading(false);
      } else if (!login?.success) {
        if (login.errorCode === "INVALID_USER") {
          setErrorMessage(
            "An invalid username or password was provided. If you have not been given access to this portal, please contact your portal administrator to be granted access."
          );
        } else {
          setErrorMessage(login.error);
        }
        setIsLoading(false);
      } else if (login.success) {
        const userDetails = await fetchJson("/api/auth/user", {
          method: "POST",
          body: JSON.stringify({ id: login.entityId }),
        });
        console.log(userDetails, "user details");
        if (!userDetails?.success) {
          setIsLoading(false);
          return setErrorMessage(userDetails.message);
        }
        if (userDetails.success) {
          const accessLogs = await fetchJson("/api/accesslogs/" + userDetails?.activeCustomer, {
            method: "POST",
            body: JSON.stringify({
              name: userDetails?.userName,
              email: userDetails?.email,
              role: userDetails?.customers?.find((item) => item.id == userDetails?.activeCustomer)?.isMasterAdmin ? 'Master Admin' : 'User',
            }),
          });
          netsuiteLib.logUserLogin();
          router.push("/dashboard");
        }
      }
    } catch (error) {
      console.log(error, "error in catch");
      setErrorMessage("There was an error logging in. Please try again.");
      setIsLoading(false);
    }
  }

  const resetPassword = async (form) => {
    setIsLoading(true);
    try {
      await form.validateFields();
      const reset = await fetchJson("/api/auth/resetpassword", {
        method: "POST",
        body: JSON.stringify({
          username: form.getFieldValue("email"),
        }),
      });

      if (!reset.success) {
        setIsLoading(false);
        return setErrorMessage(reset.message || reset.error);
      } else {
        router.push("/login?view=set_password");
      }
    } catch (e) {
      console.log(e, "e from");
      setIsLoading(false);
      if (e.errorFields) {
        return;
      }
      return setErrorMessage("There was an error. Please try again.");
    }
  };
  const [form] = Form.useForm();
  return (
    <Form form={form} data-testid="passwordloginform" {...formItemLayout} onFinish={handleLogin} name="passwordlogin">
      {errorMessage && (
        <Typography.Paragraph id="error" data-testid="errormessage" type="danger">
          {errorMessage}
        </Typography.Paragraph>
      )}
      {isFirstLogin && (
        <Typography.Paragraph>Please enter your email below to set up your account.</Typography.Paragraph>
      )}
      <Form.Item
        // wrapperCol={}
        label="Email"
        name="email"
        rules={[
          { required: "true", message: "An email is required" },
          { type: "email", message: "A valid email is required." },
        ]}
      >
        <Input type="text" data-testid="emailinput" placeholder="email@example.com" />
      </Form.Item>
      {!isFirstLogin && (
        <Form.Item
          // wrapperCol={{ offset: 4, span: 20 }}
          label="Password"
          name="password"
          rules={[{ required: "true", message: "An password is required" }]}
        >
          <Input.Password data-testid="passwordinput" type="password"  />
        </Form.Item>
      )}

      <Form.Item labelCol={0} wrapperCol={24}>
        <Row justify="end" align="middle">
          {isFirstLogin ? (
            <Button loading={isLoading} type="primary" block onClick={() => resetPassword(form)}>
              Continue
            </Button>
          ) : (
            <Button loading={isLoading} type="primary" block htmlType="submit">
              Login
            </Button>
          )}
          <Space direction="vertical">
            <Button type="link" onClick={() => resetPassword(form)}>
              Forgot your password?
            </Button>
          </Space>
        </Row>
      </Form.Item>
      <Row justify="center">
        <Button type="link" onClick={() => setIsFirstLogin((prev) => !prev)}>
          {isFirstLogin ? "Login with username and password" : "First time login?"}
        </Button>
      </Row>
    </Form>
  );
};

export default PasswordLogin;
