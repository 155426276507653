import { useEffect } from "react";
import useSWR from "swr";

export default function useLocations() {
  const {
    data: locations,
    mutate: mutateLocations,
    error,
  } = useSWR("/api/locations", {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: true,
    refreshInterval: 1000 * 1500,
    focusThrottleInterval: 1000 * 1500,
  });
  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (!locations) return;
  }, [locations]);

  return { locations: [...new Set(locations?.map(item => item.internalid))]?.map(loc => {
    const foundLocation = locations?.find(item => item.internalid == loc)
    return {
      ...foundLocation
    }
  }), error, mutateLocations };
}
