import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  Divider,
  Space,
  Card,
  notification,
  Popconfirm,
  Tooltip,
  DatePicker,
} from "antd";
import { useRouter } from "next/router";
import useLocations from "@/lib/useLocations";
import { DynamicErrorComponent, Spinner } from "@/components/common";
import { getAllLocationsAccessibleForPermissions } from "@/utils/permissions";
import fetchJson from "@/lib/fetcher";
import userlib from "@/lib/userlib";
import useUser from "@/lib/useUser";
import dayjs from 'dayjs';
import dateUtils from "@/utils/dateUtils";
import netsuiteLib from "@/lib/netsuitelib";

const Contact = (props) => {
  const [form] = Form.useForm();
  const {user} = useUser();
  const contact = props.contact;
  console.log(contact, "contact");
  const [isMasterAdmin, setIsMasterAdmin] = useState(contact?.access?.isMasterAdmin);
  const router = useRouter();
  const isEdit = !props.isCreate;
  const loading = props.isLoading;
  const submitContactUpdate = props.submitContactUpdate;
  const { locations: initialLocations, mutateLocations: mutateLocations , error: locationsError } = useLocations();
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState();

  const [permissionLocations, setPermissionLocations] = useState([]);
  const [siteAccessDatesDisabled, setSiteAccessDatesDisabled] = useState({});
  const [startDates, setStartDates] = useState({});

  const datePickerFormat = 'M/D/YYYY';

  console.log("enabledSiteAccess on Contact component", props.enabledSiteAccess);  

  useEffect(() => {
    form.resetFields();
    mutateLocations([]);
    setPermissionLocations([]);
    router.replace(router.asPath);
  }, [user?.assumedUser])

  useEffect(() => {
    if (locations?.length > 0 && locations[0] != undefined) {
      return;
    }
    if (initialLocations?.length > 0) {
      const filteredLocations = userlib.getUserAssociatedLocations(user, initialLocations);
      setLocations(getAllLocationsAccessibleForPermissions(user, filteredLocations, "contact", "create"));
    }
  }, [initialLocations]);

  useEffect(() => {
    if (permissionLocations?.length > 0 || !locations || locations?.length == 0) {
      return;
    }
    if (!permissionLocations || permissionLocations?.length == 0) {
      setPermissionLocations(
        contact?.permissions?.locations
          ?.map((item) => locations?.find((loc) => loc?.companyId == item.childCompanyId)?.internalid)
          ?.filter((item) => item)
      );
    }
  }, [locations]);
  const [formValues, setFormValues] = useState();

  if (((!initialLocations || !initialLocations.length) && !locationsError) || isLoading || loading) {
    return <Spinner />;
  }

  if (locationsError) {
    console.log(locationsError, "locations error");
    return <DynamicErrorComponent error={locationsError} />;
  }
  const getLocationCompanyId = (location) => {
    return locations?.find((item) => item.internalid == location)?.companyId;
  };
  
  const revokeLocationPermission = async (companyId) => {
    setIsLoading(true);
    try {
      const matchingLocationIndex = contact?.permissions?.locations?.findIndex((item) => item.childCompanyId == companyId);
      const matchingLocation = contact.permissions.locations[matchingLocationIndex];
      const values = {
        id: contact?.userId,
        permissions: {
          id: matchingLocation.portalUserId,
          locations: [
            {
              __linekey: matchingLocationIndex,
              __delete: true,
              id: matchingLocation.internalid,
              childCompanyId: matchingLocation.childCompanyId,
            },
          ],
        },
      };
      console.log(values, "values to send");
      const req = await fetchJson("/api/contacts/" + contact?.userId, {
        body: JSON.stringify(values),
        method: "POST",
        "content-type": "application/json",
      });
      setIsLoading(false);
      if (req.success) {
        netsuiteLib.logLocationAccessRevoked(contact, matchingLocation);
        router.reload();
        notification.success({
          message: "Access revoked",
          description: "Access for the location has been revoked.",
          placement: "bottomRight",
        });
      } else {
        notification.error({
          message: "An error occurred.",
          description: "We were unable to revoke access to the location. Please try again.",
          placement: "bottomRight",
        });
      }
    } catch (e) {
      console.log(e, "e in revoke");
      setIsLoading(false);
      notification.error({
        message: "An error occurred.",
        description: "We were unable to revoke access to the location. Please try again.",
        placement: "bottomRight",
      });
    }
  };
  const revokeAllAccess = async () => {
    setIsLoading(true);
    try {
      const values = {
        id: contact?.id,
        permissions: {
          isDisabled: true,
          id: contact?.permissions?.id,
          __delete: true,
          locations: contact?.permissions?.locations?.map((item) => {
            return {
              __linekey: item["__linekey"],
              __delete: true,
              id: item.id,
              childCompanyId: item.childCompanyId,
            };
          }),
        },
      };
      console.log(values, "values to send");
      const req = await fetchJson("/api/contacts/" + contact?.id, {
        body: JSON.stringify(values),
        method: "POST",
        "content-type": "application/json",
      });
      setIsLoading(false);
      if (req.success) {
        router.reload();
        notification.success({
          message: "Access revoked",
          description: "The user's access to the portal has been revoked.",
          placement: "bottomRight",
        });
      } else {
        notification.error({
          message: "An error occurred.",
          description: "We were unable to revoke access to the portal. Please try again.",
          placement: "bottomRight",
        });
      }
    } catch (e) {
      console.log(e, "e in revoke");
      setIsLoading(false);
      notification.error({
        message: "An error occurred.",
        description: "We were unable to revoke access to the location. Please try again.",
        placement: "bottomRight",
      });
    }
  };
  const checkInitialPermissions = (location, permission) => {
    const activeLocation = userlib.getLocationById(user, location);
    if (userlib.isMasterAdmin(user)) {
      return true;
    }
    if (userlib.isLocationAdmin(user)) {
      return true;
    }
    return activeLocation && activeLocation[permission];
  };

  const setLocationAdmin = (location, isChecked) => {
    console.log(location, isChecked);
    const companyId = getLocationCompanyId(location);
    if (isChecked) {
      form.setFieldsValue({
        locations: {
          [companyId]: {
            viewInvoiceAccess: true,
            accessLogAccess: true,
            contractAccess: true,
            createCasesAccess: true,
            requestInstallDeinstall: true,
            viewCMMSAccess: true,
            remoteHandsAccess: true,
            shipmentTicketAccess: true,
            accessTicketAccess: true,
            complianceAccess: true
          },
        },
      });
    }
  };

  const permanentSiteAccessChanged = (location, isChecked) => {
    if (siteAccessDatesDisabled[location] != isChecked) {
      setSiteAccessDatesDisabled({...siteAccessDatesDisabled, [location]: isChecked})
    }
  }

  const startDateChanged = (location, value) => {
    if (startDates[location] != value) {
      setStartDates({...startDates, [location]: value});
    }
  }

  const getLocationIdByCompanyId = (companyId) => {
    return initialLocations?.find(location => { return location.companyId == companyId} )?.internalid;
  }

  return (
    <div style={{ width: "100%", maxWidth: 1200, margin: "auto" }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={submitContactUpdate}
        name="contact"
        style={{ width: "100%" }}
        onValuesChange={(val) => {
          console.log(val, "val", Object.keys(val)[0]);
          if (
            user?.customers?.filter((item) => item.createContactsAccess)?.some((item) => item.id == Object.keys(val)[0])
          ) {
            if (formValues?.hasOwnProperty(Object.keys(val)[0])) {
              const existingValue = formValues[Object.keys(val)[0]];
              const newValue = {
                ...existingValue,
                ...val[Object.keys(val)[0]],
              };
              const newObject = formValues;
              newObject[Object.keys(val)[0]] = newValue;
              console.log(newObject, "new object");
              setFormValues({ ...newObject });
            } else {
              setFormValues({ ...formValues, ...val });
            }
          }
        }}
        initialValues={
          isEdit
            ? {
                firstname: contact?.firstName,
                lastname: contact?.lastName,
                email: contact?.email,
                phone: contact?.phone,
                isbillingcontact: contact?.isbillingcontact,
                islegalcontact: contact?.islegalcontact,
                isinactive: contact?.isinactive,
                permissions: {
                  isMasterAdmin: contact?.access?.isMasterAdmin,
                },
                locations: {
                  ...(contact?.permissions?.locations?.length > 0
                    ? contact?.permissions?.locations?.reduce((accu, curr) => {
                        var locationId = getLocationIdByCompanyId(curr.childCompanyId);
                        if (locationId && curr.permanentSiteAccess && siteAccessDatesDisabled[locationId] === undefined) {
                            permanentSiteAccessChanged(locationId, curr.permanentSiteAccess);
                        }
                        if (curr.siteAccessStartDate) {
                          curr.siteAccessStartDate = dateUtils.formatLocationAccessDateFromNetsuite(curr.siteAccessStartDate);
                          if (locationId && startDates[locationId] === undefined) {
                            startDateChanged(locationId, curr.siteAccessStartDate);
                          }
                        }
                        if (curr.siteAccessEndDate) {
                          curr.siteAccessEndDate = dateUtils.formatLocationAccessDateFromNetsuite(curr.siteAccessEndDate);
                        }

                        accu[curr.childCompanyId] = {
                          ...curr,
                        };
                        return accu;
                      }, {})
                    : locations?.reduce((accu, curr) => {
                        accu[curr.companyId] = {
                          viewInvoiceAccess: false,
                          accessLogAccess: false,
                          contractAccess: false,
                          createCasesAccess: false,
                          requestInstallDeinstall: false,
                          viewCMMSAccess: false,
                          remoteHandsAccess: false,
                          shipmentTicketAccess: false,
                          accessTicketAccess: false,
                          complianceAccess: false,
                          permanentSiteAccess: false
                        };
                        return accu;
                      }, {})),
                },
              }
            : null
        }
      >
        <Card>
          <Row justify="center" style={{ width: "100%" }} align="middle" gutter={{ xs: 4, sm: 8, md: 12, lg: 24 }} wrap>
            <Col xs={24} md={12}>
              <Form.Item
                style={{ width: "100%" }}
                label="First Name"
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: "Please enter a first name.",
                  },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Last Name"
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: "Please enter a last name.",
                  },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" align="middle" gutter={{ xs: 4, sm: 8, md: 12, lg: 24 }} wrap style={{ width: "100%" }}>
            <Col xs={24} md={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter an email.",
                  },
                  {
                    type: "email",
                    message: "A valid email is required.",
                  },
                ]}
              >
                <Input type="email" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  { min: 7, message: "The phone number must be a minimum of 7 digits if it is going to be provided." },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" align="middle" gutter={{ xs: 4, sm: 8, md: 12, lg: 24 }} wrap style={{ width: "100%" }}>
            <Col xs={24} md={12}>
              <Form.Item
                  valuePropName="checked"
                  tooltip="Mark as Billing Contact"
                  label="Billing Contact"
                  name="isbillingcontact"
                  initialValue={false}
                >
                  <Checkbox/>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                  valuePropName="checked"
                  tooltip="Mark as Legal Contact"
                  label="Legal Contact"
                  name="islegalcontact"
                  initialValue={false}
                >
                  <Checkbox/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 4, sm: 8, md: 12, lg: 24 }} wrap style={{ width: "100%" }}>
          <Col>
              <Form.Item
                  valuePropName="checked"
                  tooltip="Set Contact as Inactive"
                  label="Inactive"
                  name="isinactive"
                  initialValue={false}
                  disabled={!userlib.isMasterAdmin(user)}
                >
                  <Checkbox/>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            {contact?.permissions?.id && !contact?.permissions?.isDisabled && (
              <Popconfirm
                okText="Revoke"
                cancelText="Cancel"
                title={"Are you sure you want to remove access to the portal for this user?"}
                onCancel={null}
                onConfirm={() => revokeAllAccess()}
              >
                <Tooltip title="All access to the portal will be removed.">
                  <Button danger>Revoke All Portal Access</Button>
                </Tooltip>
              </Popconfirm>
            )}
          </Row>
        </Card>
        <Divider>Portal Permissions</Divider>
        <Card>
          <Row gutter={16} justify="center" align="top">
            <Col xs={24}>
              <Form.Item
                valuePropName="checked"
                tooltip="A Master Admin will have all permissions for all locations. Setting a user as a master admin does not allow you to set permissions per location."
                label="Master Admin"
                name={["permissions", "isMasterAdmin"]}
                initialValue={false}
              >
                <Checkbox
                  disabled={!userlib.isMasterAdmin(user)}
                  onChange={(e) => setIsMasterAdmin(e.target.checked)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              <Row align="middle" justify="left" wrap={false}>
                <span>Locations: </span>
                <Select
                  style={{ width: "100%", marginLeft: 8}}
                  mode="multiple"
                  value={permissionLocations}
                  onSelect={(val) => setPermissionLocations([...(permissionLocations ? permissionLocations : []), val])}
                  onDeselect={(val) => {
                    if (
                      contact?.permissions?.locations?.find((item) => item.childCompanyId == getLocationCompanyId(val))
                    ) {
                      return notification.error({
                        message: "You cannot remove this location",
                        description: "To remove access to this, please press the revoke access button below.",
                        placement: "bottomRight",
                      });
                    } else {
                      setPermissionLocations(permissionLocations?.filter((item) => item != val));
                    }
                  }}
                >
                  {locations?.map((item) => (
                    <Select.Option key={item.name} value={item.internalid}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Row>
            </Col>
          </Row>

          <Row
            justify="center"
            align="middle"
            gutter={8}
            wrap
          >
            {permissionLocations?.length > 0 &&
              permissionLocations.map((location) => (
                <div key={location} style={{ width: "100%"}}>
                  <Col xs={24}>
                    <Row justify="left" align="middle" gutter={0} style={{ marginBottom: 10, marginTop: 10 }}>
                      <h4 className="nunito-sans" style={{ fontWeight:"bold", textAlign: "right", fontSize: "2.5rem", marginTop: "6px", marginBottom: "20px", height: "36px"}}>
                        {locations?.find((loc) => loc.internalid == location)?.name}
                      </h4>
                      <Divider style={{ margin: "3px 0px 3px 0px" }} />
                      {contact?.permissions?.locations?.find(
                        (loc) => loc.childCompanyId == getLocationCompanyId(location)
                      ) && (
                        <Popconfirm
                          okText="Revoke"
                          cancelText="Cancel"
                          title={
                            <>
                              <p style={{marginBottom: 3}}>
                                Are you sure you want to remove access to{" "}
                                {locations?.find((loc) => loc.internalid == location)?.name}?
                              </p>
                              <p style={{marginBottom: 3}}>Please note, this does not remove inherent permissions available.</p>
                              <p style={{marginBottom: 3}}>To remove ALL access, use the Revoke All Portal Access button above.</p>
                            </>
                          }
                          onCancel={null}
                          onConfirm={() => revokeLocationPermission(getLocationCompanyId(location))}
                        >
                          <div className="flex-right">
                            <Tooltip placement="left" title="Removes access to a specific location only. Inherent permissions will remain, like viewing cases. To remove all access, use the Revoke All Portal Access button above.">
                              <Button style={{marginTop:"4px"}} danger type="default">
                                Revoke Location Access
                              </Button>
                            </Tooltip>
                          </div>
                        </Popconfirm>
                      )}
                    </Row>
                  </Col>
                  {!isMasterAdmin &&
                  <div>
                    <Row justify="left" align="middle" gutter={8} className="contact-permissions-container-top" wrap>
                    <Col xs={12} md={8} lg={6}>
                      <Form.Item
                        name={["locations", getLocationCompanyId(location), "isLocationAdmin"]}
                        valuePropName="checked"
                        tooltip="A location admin will be granted all permissions for the selected location, as well as the ability to manage permissions and users within the location. Any other permission settings for this location will be overwritten."
                        label="Location Admin"
                      >
                        <Checkbox
                          disabled={
                            !userlib.isMasterAdmin(user) &&
                            !userlib.isLocationAdmin(user)
                          }
                          onChange={(e) => setLocationAdmin(location, e.target.checked)}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={8} lg={6}>
                      <Form.Item
                        valuePropName="checked"
                        tooltip="Allow permission to view invoices."
                        label="View Invoices"
                        name={["locations", getLocationCompanyId(location), "viewInvoiceAccess"]}
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={8} lg={6}>
                      <Form.Item
                        valuePropName="checked"
                        tooltip="Allow permission to request Remote Hands."
                        label="Request Remote Hands"
                        name={["locations", getLocationCompanyId(location), "remoteHandsAccess"]}
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={8} lg={6}>
                      <Form.Item
                        valuePropName="checked"
                        tooltip="Allows the creation of shipment related cases."
                        label="Create Shipment Cases"
                        name={["locations", getLocationCompanyId(location), "shipmentTicketAccess"]}
                      >
                        <Checkbox />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={8} lg={6}>
                      <Form.Item
                        name={["locations", getLocationCompanyId(location), "createCasesAccess"]}
                        valuePropName="checked"
                        tooltip="Allows permission to create trouble cases"
                        label="Create Trouble Cases"
                      >
                        <Checkbox disabled={!checkInitialPermissions(location, "createCasesAccess")} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={8} lg={6}>
                      <Form.Item
                        name={["locations", getLocationCompanyId(location), "requestInstallDeinstall"]}
                        valuePropName="checked"
                        label="Request Installs/Deinstalls"
                      >
                        <Checkbox disabled={!checkInitialPermissions(location, "requestInstallDeinstall")} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={8} lg={6}>
                      <Form.Item
                        valuePropName="checked"
                        tooltip="Allows creation of cases to request permanent and visitor badges."
                        label="Create Access Request"
                        name={["locations", getLocationCompanyId(location), "accessTicketAccess"]}
                      >
                        <Checkbox disabled={!checkInitialPermissions(location, "accessTicketAccess")} />
                      </Form.Item>
                    </Col>
                    {/* <Col xs={12} md={8} lg={6}>
                      <Form.Item
                        name={["locations", getLocationCompanyId(location), "accessLogAccess"]}
                        valuePropName="checked"
                        label="View Access Logs"
                      >
                        <Checkbox disabled={!checkInitialPermissions(location, "accessLogAccess")} />
                      </Form.Item>
                    </Col> */}
                    <Col xs={12} md={8} lg={6}>
                      <Form.Item
                        name={["locations", getLocationCompanyId(location), "contractAccess"]}
                        valuePropName="checked"
                        label="View Contracts"
                      >
                        <Checkbox disabled={!checkInitialPermissions(location, "contractAccess")} />
                      </Form.Item>
                    </Col>
                    {/* <Col xs={12} md={8} lg={6}>
                      <Form.Item
                        name={["locations", getLocationCompanyId(location), "viewCasesAccess"]}
                        valuePropName="checked"
                        label="View Cases"
                      >
                        <Checkbox disabled={!checkInitialPermissions(location, "viewCasesAccess")} />
                      </Form.Item>
                    </Col> */}
                    {/* <Col xs={12} md={8} lg={6}>
                      <Form.Item
                        name={["locations", getLocationCompanyId(location), "viewInstallDeinstallRequests"]}
                        valuePropName="checked"
                        label="View Pending Install/Deinstall Requests"
                      >
                        <Checkbox disabled={!checkInitialPermissions(location, "viewInstallDeinstallRequests")} />
                      </Form.Item>
                    </Col> */}
                    <Col xs={12} md={8} lg={6}>
                      <Form.Item
                        name={["locations", getLocationCompanyId(location), "viewCMMSAccess"]}
                        valuePropName="checked"
                        tooltip="Should this contact receive notifications for maintenance events?"
                        label="Maintenance Notifications"
                      >
                        <Checkbox disabled={!checkInitialPermissions(location, "viewCMMSAccess")} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={8} lg={6}>
                      <Form.Item
                        name={["locations", getLocationCompanyId(location), "complianceAccess"]}
                        valuePropName="checked"
                        tooltip="Will this contact be able to request compliance reports and client services requests."
                        label="Compliance Reports"
                      >
                        <Checkbox disabled={!checkInitialPermissions(location, "complianceAccess")} />
                      </Form.Item>
                    </Col>
                    
                    {/* <Col xs={12} md={8} lg={6}>
                      <Form.Item
                        name={["locations", getLocationCompanyId(location), "viewReportsAccess"]}
                        valuePropName="checked"
                        tooltip="Will this contact be able to view bandwidth and power usage reports."
                        label="Usage Reports"
                      >
                        <Checkbox disabled={!checkInitialPermissions(location, "viewReportsAccess")} />
                      </Form.Item>
                    </Col> */}
                    {/* <Col xs={12} md={8} lg={6}>
                      <Form.Item
                        name={["locations", getLocationCompanyId(location), "viewContactsAccess"]}
                        valuePropName="checked"
                        label="View Contacts"
                      >
                        <Checkbox disabled={!checkInitialPermissions(location, "viewContactsAccess")} />
                      </Form.Item>
                    </Col> */}
                    {/* <Col xs={12} md={8} lg={6}>
                      <Form.Item
                        name={["locations", getLocationCompanyId(location), "createContactsAccess"]}
                        valuePropName="checked"
                        label="Create Contacts"
                      >
                        <Checkbox disabled={!checkInitialPermissions(location, "createContactsAccess")} />
                      </Form.Item>
                    </Col> */}
                    </Row>
                  </div>}
                  <Row justify="left" align="middle" gutter={8} className={isMasterAdmin ? "contact-permissions-container-bottom-admin" : "contact-permissions-container-bottom"} wrap>
                    {/* Site Access Permissions */}
                    {props.enabledSiteAccess && (<>
                      <Divider style={{ marginTop: 0 }}>Physical Site Access</Divider>
                      <Col xs={12} md={8} lg={6}>
                        <Form.Item
                          name={["locations", getLocationCompanyId(location), "permanentSiteAccess"]}
                          tooltip={`Does this contact have permanent physical access to the ${locations?.find((loc) => loc.internalid == location)?.name} site?`}
                          label="Permanent Site Access"
                          valuePropName="checked"
                        >
                          <Checkbox disabled={!userlib.isMasterAdmin(user)} onChange={(e) => permanentSiteAccessChanged(location, e.target.checked)} />
                        </Form.Item>
                      </Col>
                      {/* Start Date */}
                      <Col xs={12} md={8} lg={6}>
                        <Form.Item
                          name={["locations", getLocationCompanyId(location), "siteAccessStartDate"]}
                          tooltip={`Start date of physical access to the ${locations?.find((loc) => loc.internalid == location)?.name} site?`}
                          label="Temporary Start Date"
                        >
                          <DatePicker
                            disabled={siteAccessDatesDisabled[location]}
                            format={datePickerFormat}
                            minDate={dayjs()}
                            maxDate={dayjs().add(365, 'day')}
                            onChange={date => startDateChanged(location, date)}
                          />
                        </Form.Item>
                      </Col>
                      {/* End Date */}
                      <Col xs={12} md={8} lg={6}>
                        <Form.Item
                          name={["locations", getLocationCompanyId(location), "siteAccessEndDate"]}
                          tooltip={`End date of physical access to the ${locations?.find((loc) => loc.internalid == location)?.name} site?`}
                          label="Temporary End Date"
                          rules={[
                            {
                              required: startDates[location] && !siteAccessDatesDisabled[location],
                              message: "End Date selection required."
                            },
                            {
                              message: 'End Date must not be before Start Date',
                              validator: (_, value) => {
                                if (siteAccessDatesDisabled[location] || !startDates[location] || !value.isBefore(startDates[location])) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject();
                                }
                              }
                            }
                          ]}
                        >
                          <DatePicker
                            disabled={siteAccessDatesDisabled[location] || !startDates[location]}
                            format={datePickerFormat}
                            minDate={startDates[location] && dayjs().isBefore(startDates[location]) ? startDates[location] : dayjs()}
                            maxDate={startDates[location] && startDates[location].add(365, 'day')}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={8} lg={6}>
                        <Form.Item
                          name={["locations", getLocationCompanyId(location), "siteAccessLevel"]}
                          tooltip={`Additional access information for ${locations?.find((loc) => loc.internalid == location)?.name} site? (Optional)`}
                          label="Access Level (Optional)"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </>)}
                   
                  </Row>
                </div>
              ))}
          </Row>
          <Row justify="end" align="middle" gutter={0} style={{ marginTop: 16 }}>
            <Form.Item>
              <Space>
                <Button onClick={() => router.push("/dashboard/contacts")}>Cancel</Button>
                <Button type="primary" loading={isLoading} htmlType="submit">
                  Save Contact
                </Button>
              </Space>
            </Form.Item>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Contact;
